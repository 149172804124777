import React, { ReactElement } from "react";
import Image from "next/image";

export default function WhoIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-36 gap-16 lg:mt-28 mt-16  lg:pl-12">
            <div className="max-w-[574px]  mx-auto lg:mx-0 order-1 lg:order-2">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#2D381B]">
                    Who Is a Home Warranty For?
                </h2>
                <p className="mt-4 font-light  lg:text-lg text-base lg:leading-8 text-[#232323]">
                    A home warranty is ideal for homeowners who wish to shield
                    their appliances and systems from unexpected breakdowns and
                    repairs. It's particularly useful for those who aren't handy
                    with repairs or lack time to handle maintenance issues. Home
                    warranties also provide peace of mind to homeowners by
                    covering home systems in case of unexpected breakdowns.
                </p>
                <span className="w-[143px] h-[7px] block bg-[#E58D3D] mt-6"></span>
            </div>
            <div className="w-[300px] h-[210px] md:h-[337px] md:w-[440px] md:min-w-[440px] relative mx-auto lg:mx-0 order-2 lg:order-1">
                <Image
                    src={
                        "/gethomeprotection/assets/images/whoIsHomeWarranty.jpg"
                    }
                    alt="image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
