import React, { ReactElement } from "react";

import WhatIsHomeWarrantyIcon from "../icons/whatIsHomeWarrantyImg";

export default function WhatIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row   lg:mt-40 mt-20 rounded-[20px] bg-[#FFF6F1] lg:px-20 px-5 py-9 relative gap-10 lg:gap-0">
            <div className=" ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  ">
                    What Is a Home Warranty?
                </h2>
                <p className="mt-2 lg:max-w-[515px] font-light lg:text-lg text-base">
                    A home warranty is a service plan that covers repair or
                    replacement costs for household appliances and systems. It's
                    a yearly subscription, but the coverage and terms can vary
                    depending on your chosen plan.
                </p>
            </div>
            <div className="lg:w-[422px] lg:min-w-[422px] lg:h-[287px] w-[300px] h-[200px]  lg:absolute bottom-[17px] right-[27px]  mx-auto lg:mx-0">
                <WhatIsHomeWarrantyIcon />
            </div>
        </section>
    );
}
