import React, { ReactElement } from "react";
import Image from "next/image";

export default function HowIsDifferent(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-36 gap-16 lg:mt-28 mt-16 ">
            <div className="max-w-[574px]  mx-auto lg:mx-0">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#2D381B]">
                    How Is a Home Warranty Different From Homeowner's Insurance?
                </h2>
                <p className="mt-4 font-light  lg:text-lg text-base lg:leading-8 text-[#232323]">
                    While homeowner's insurance covers damage to your property
                    and the house's structure, a home warranty focuses on
                    protecting your appliances and systems. It's an intelligent
                    investment to safeguard your home, but it doesn't replace
                    homeowner's insurance. Additionally, manufacturer warranties
                    may cover some of your home systems if you've recently
                    purchased a new home.
                </p>
                <span className="w-[143px] h-[7px] block bg-[#E58D3D] mt-6"></span>
            </div>
            <div className="w-[300px] h-[210px] md:h-[337px] md:w-[440px] md:min-w-[440px] relative mx-auto lg:mx-0">
                <Image
                    src={"/gethomeprotection/assets/images/howIsDifferent.jpg"}
                    alt="image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
